import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import moment from "moment";
import "./Homepage.styles.css";
import { useNavigate, useParams } from "react-router-dom";
import bannerImg from "../../assets/images/flight/banner-img.jpg";
import { phoneNum } from "../../utils/globalVars";
import { useState, useEffect } from "react";
import SEO from "../../utils/SEO";


const HomePage = () => {
  
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleExpClick = (origin, dest) => {
    navigate({
      pathname: "/flights",
      search: `?search_t=${moment().unix()}&tripType=one-way&dep_loc=${origin}&dest_loc=${dest}&dep_dt=${moment().format(
        "YYYY-MM-DD"
      )}&ret_dt=null&fl_cl=ECONOMY&adt=1&chd=0`,
    });
  };

  const [showPopup, setPopup] = useState('active')

  function removePopup(){
    setPopup('')
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const offersettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: '100px',
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: '70px',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: '50px',
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };


  // const [homepageStatus, setCloseHome] = React.useState(false);
  // var [count, setCount] = React.useState(0);
  // const handleToClick = () => {
  //   count = count + 1;
  //   if (count >= 10) {
  //     setCloseHome(true);
  //   document.body.classList.add('overflow');

  //   } else {
  //     setCount(count);
  //     window.location.href = 'tel:' + phoneNum.value;
  //   }
  // }
  // const handleTocall = () => {
  //   window.location.href = 'tel:' + phoneNum.value;
  // }
  return (
    <>
    <SEO 
      title="Flightticketcharge -Ultimate Destination for Cheap Flight Booking" 
      description="Flightticketcharge - Your ultimate destination for hassle-free travel bookings. Find the best deals on flights, and embark on your dream journey today. Visit us"
    />
      {/* <div className={`Modal ${homepageStatus ? "pop-modal-mob" : ""}`} onClick={handleToClick}>
        <a href={`tel:${phoneNum.value}`} className="reservation-popup">
          <div className="reservation-inner">
              <div className="wrapper">
                  <div  className="call-number">
                      <strong><i className="fa fa-phone" aria-hidden="true"></i>{phoneNum.value}</strong>
                      <p>call for reservations</p>
                  </div>
                  <h3>Call for Reservations </h3>
                  <div className="call-btn"><span><i className="fa fa-phone" aria-hidden="true"></i></span>{phoneNum.value}</div>
                  <div className="call-img">
                      <img src={gcallIcon} alt=""/>
                  </div>
              </div>
              <div className="footer">
                  <h3>Call for Reservations</h3>
                  <div className="footer-btn">
                      <span><i className="fa fa-phone" aria-hidden="true"></i></span>
                      <div className="footer-inner">
                          <p>Get Our Best Deals</p>

                          <strong>{phoneNum.value}</strong>
                      </div>
                  </div>
              </div>
          </div>
      </a>
      </div >  */}


      <div className="home-banner">
        <img src={bannerImg} alt="" />
        <div className="banner-text">
          <h1>Explore the world together</h1>
        </div>
      </div>

      <div className="home-banner-search">
        <div className="cm-page-center">
            <div className="search-banner">
              <FlightSearchForm />
            </div>
          </div>
      </div>

      <div className="beyond-section">
        <div className="cm-page-center cm-flex cm-flex-wrap">
            <div className="cm-wd-25 cm-flex-type-2">
                <h2>Go beyond your imagination</h2>
                <p>Discover your ideal experience with us</p>
            </div>
            <div className="cm-wd-25">
              <div className="imagination_boxed">
                <figure>
                  <img src="images/imagination.png" alt="" />
                </figure>
                <h3>7% Discount for all Airlines</h3>
              </div>
            </div>
            <div className="cm-wd-25">
              <div className="imagination_boxed">
                <figure>
                  <img src="images/imagination2.png" alt="" />
                </figure>
                <h3>Travel around the world</h3>
              </div>
            </div>
            <div className="cm-wd-25">
              <div className="imagination_boxed">
                <figure>
                  <img src="images/imagination3.png" alt="" />
                </figure>
                <h3>Luxury resorts top deals</h3>
              </div>
            </div>
        </div>
      </div>

      <div className="service_section">
        <h2>Here's why travelers choose flightticketcharge.com</h2>
        <div className="cm-page-center cm-flex cm-flex-wrap">
            <div className="cm-wd-25">
            <i className="fa fa-get-pocket" aria-hidden="true"></i>
              <h5>Get Great Deals!</h5>
                <p>Choose from 500+ airlines for low airfares!</p>
            </div>
            <div className="cm-wd-25">
            <i className="fa fa-mars" aria-hidden="true"></i>
              <h5>Price Match Promise</h5>
                <p>Find low prices to destinations worldwide.</p>
            </div>
            <div className="cm-wd-25">
            <i className="fa fa-ban" aria-hidden="true"></i>
              <h5>Easy Cancellations with flightticketcharge.com</h5>
                <p>Convenient self-service options available online.</p>
            </div>
            <div className="cm-wd-25">
              <i className="fa fa-envira" aria-hidden="true"></i>
              <h5>Expert Guidance</h5>
                <p>Get personalized assistance from our travel experts.</p>
            </div>
        </div>
      </div>

      <div className="offer-section">
        <div className=" cm-page-center">
          <div className="offer-title">
            <h2>Flightticketcharge Best Tours</h2>
          </div>
          <div className="offer-slider-row cm-flex cm-flex-wrap">
                <div className="column cm-wd-25" onClick={(e) => handleExpClick("JFK", "LHR")}>
                    <div className="col-inner">
                        <a href="#" className="image-box">
                            <img src="./images/traditional-gondola-near-world-famous-canal-grande-rialto-bridge.png" alt="" />
                        </a>
                        <div className="offter-text">
                            <div className="title">Thrilling Italy</div>
                            <div className="cm-flex-type-1">
                              <p><span>JFK <i className="fa-solid fa-arrow-right-arrow-left"></i> LHR</span></p>
                              <p><strong>$155</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column cm-wd-25" onClick={(e) => handleExpClick("MAD", "LHR")}>
                    <div className="col-inner">
                        <a href="#" className="image-box">
                        <img src="./images/colosseum-rome-morning-sun-italy.png" alt="" />
                        </a>
                        <div className="offter-text">
                            <div className="title">Big in spain</div>
                            <div className="cm-flex-type-1">
                              <p><span>MAD <i className="fa-solid fa-arrow-right-arrow-left"></i> LHR</span></p>
                              <p><strong>$100</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column cm-wd-25" onClick={(e) => handleExpClick("ATL", "JFK")}>
                    <div className="col-inner">
                        <a href="#" className="image-box">
                        <img src="./images/view-great-sphinx.png" alt="" />
                        </a>
                        <div className="offter-text">
                            <div className="title">Trip to Ancient Egypt</div>
                            <div className="cm-flex-type-1">
                              <p><span>ATL <i className="fa-solid fa-arrow-right-arrow-left"></i> JFK</span></p>
                              <p><strong>$120</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column cm-wd-25" onClick={(e) => handleExpClick("SYD", "LHR")}>
                    <div className="col-inner">
                        <a href="#" className="image-box">
                        <img src="./images/downtown-chicago-skyline-sunset-illinois-usa.png" alt="" />
                        </a>
                        <div className="offter-text">
                            <div className="title">Faraway Baston</div>
                            <div className="cm-flex-type-1">
                              <p><span>SYD <i className="fa-solid fa-arrow-right-arrow-left"></i> LHR</span></p>
                              <p><strong>$147</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column cm-wd-25" onClick={(e) => handleExpClick("BER", "LHR")}>
                    <div className="col-inner">
                        <a href="#" className="image-box">
                        <img src="./images/paris-eiffel-tower-skyline-aerial-france.png" alt="" />
                        </a>
                        <div className="offter-text">
                            <div className="title">Colorful Paris</div>
                            <div className="cm-flex-type-1">
                              <p><span>BER <i className="fa-solid fa-arrow-right-arrow-left"></i> LHR</span></p>
                              <p><strong>$135</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column cm-wd-25" onClick={(e) => handleExpClick("TYO", "BOS")}>
                    <div className="col-inner">
                        <a href="#" className="image-box">
                        <img src="./images/tokyo.jpg" alt="" />
                        </a>
                        <div className="offter-text">
                            <div className="title">Beautiful Tokyo</div>
                            <div className="cm-flex-type-1">
                              <p><span>TYO <i className="fa-solid fa-arrow-right-arrow-left"></i> BOS</span></p>
                              <p><strong>$189</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column cm-wd-25" onClick={(e) => handleExpClick("LHR", "JFK")}>
                    <div className="col-inner">
                        <a href="#" className="image-box">
                        <img src="./images/big-ben-clock-tower-london-england.png" alt="" />
                        </a>
                        <div className="offter-text">
                            <div className="title">A weekend in London</div>
                            <div className="cm-flex-type-1">
                              <p><span>LHR <i className="fa-solid fa-arrow-right-arrow-left"></i> JFK</span></p>
                              <p><strong>$135</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column cm-wd-25" onClick={(e) => handleExpClick("AMS", "LOS")}>
                    <div className="col-inner">
                        <a href="#" className="image-box">
                        <img src="./images/amsterdam.jpg" alt="" />
                        </a>
                        <div className="offter-text">
                            <div className="title">Awesome Amsterdam</div>
                            <div className="cm-flex-type-1">
                              <p><span>AMS <i className="fa-solid fa-arrow-right-arrow-left"></i> LOS</span></p>
                              <p><strong>$110</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    
      <div className="cm-section review-section">
        <div className="cm-page-center">
          <h2 className="cm-txt-center"> Customer Reviews </h2>

          <Slider {...settings} className="cm-home-guide-slider">
                <div className="cm-home-guide-slide">
                  <p>Flight Trip Charge created an incredible tour for my family and relatives employing pocket-friendly last-minute travel flights.</p>
                  {/* <strong>Customer Name</strong>   */}
                </div>
                <div className="cm-home-guide-slide">
                  <p>Wonderful time with you all! The service was really helpful in aiding me with all aspects of my flight change procedures.</p>
                  {/* <strong>Customer Name</strong> */}
                </div>
            </Slider>
        </div>
      </div>

    </>
    
  );
};

export default HomePage;
